<template>
  <KPage>
    <!-- Barra de pesquisa -->
    <FilterBar />
    <v-card outlined class="overflow-hidden">
      <v-row no-gutters>
        <v-col cols="12" class="pa-0">
          <v-list class="pa-0 bordered">
            <template v-for="(form, key) in faq_categories">
              <v-list-item
                :key="'item-' + key"
                @click.stop.prevent="edit(form)"
              >
                <!--  -->
                <v-list-item-avatar>
                  <v-icon>{{ form.icon }}</v-icon>
                </v-list-item-avatar>
                <!-- Conteúdo -->
                <v-list-item-content>
                  <!-- Primeira linha -->
                  <v-list-item-title v-text="form.name"></v-list-item-title>

                  <!-- Segunda linha -->
                  <v-list-item-subtitle>
                    {{ form.description }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list>
        </v-col>
      </v-row>
    </v-card>

    <!-- Caixa de Diálogo -->
    <DialogSave ref="dialog_save" />

    <!-- Botão FAB -->
    <FAB :visible="!loading" @click="add"></FAB>
  </KPage>
</template>

<script>
import KPage from "@/components/KPage";
import FAB from "@/components/FAB";

// Internos
import FilterBar from "./FilterBar";

// Dialogs
import DialogSave from "./DialogSave";

export default {
  components: {
    KPage,
    FAB,
    //
    FilterBar,
    DialogSave
  },

  data() {
    return {
      //
      // faq_categories: [],

      //
      loading: false,

      //
      dialog: {
        visible: true
      }
    };
  },

  computed: {
    /**
     * Alimenta os partidos de forma reativa
     */
    faq_categories() {
      return this.$store.getters["faq_categories/all"];
    }
  },

  //
  methods: {
    /**
     * Abre o dialog como cadastro
     */
    add() {
      this.$refs["dialog_save"].add();
    },

    /**
     * Abre o dialog_save como cadastro
     */
    edit(data) {
      this.$refs["dialog_save"].edit(data);
    },

    /**
     *
     */
    remove(data) {
      this.$refs["dialog_delete"].openToRemove(data);
    },

    /**
     * Carrega os partidos caso ainda não estejam
     */
    async loadData() {
      try {
        this.loading = true;
        this.$loading.show();
        await this.$store.dispatch("faq_categories/all");
        this.$loading.hide();
        this.loading = false;
      } catch (error) {
        this.$message.serverError(error);
        this.loading = false;
        this.$loading.hide();
      }
    }
  },

  mounted() {
    this.loadData();
  }
};
</script>
