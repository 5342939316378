<template>
  <v-fab-transition>
    <v-btn
      color="secondary"
      v-show="visible"
      dark
      fixed
      bottom
      right
      fab
      @click.stop.prevent="sendEvent"
    >
      <v-icon>{{icon}}</v-icon>
    </v-btn>
  </v-fab-transition>
</template>

<script>
export default {
  props: {
    icon: { type: String, default: "mdi-plus" },
  },

  data() {
    return {
      visible: false,
    };
  },
  methods: {
    sendEvent() {
      this.$emit("click");
      //console.log("clicou no FAB");
    },
  },
  mounted() {
    setTimeout(() => (this.visible = true), 500);
  },
  beforeDestroy() {
    //console.log("saindo do fab");
    this.visible = false;
  },
};
</script>

<style>
</style>