<template>
  <div>
    <!-- Está publicado -->
    <KChip class="mr-1" :green="is_published == 1" :grey="is_published == 0">{{
      is_published ? "Publicado" : "Rascunho"
    }}</KChip>

    <!-- Sem categoria -->
    <KChip v-if="!category" icon="mdi-label-off-outline">Sem categoria</KChip>

    <!-- Sem categoria -->
    <KChip v-if="url" icon="mdi-github" cyan>Importado</KChip>

    <!-- Nome da categoria -->
    <KChip v-if="category" :icon="category.icon">{{ category.name }}</KChip>

    <!-- Número de imagens -->
    <KChip v-if="images && images.length" icon="mdi-image-outline">
      {{ images.length }} image{{ images.length === 1 ? "m" : "ns" }}
    </KChip>
  </div>
</template>

<script>
import KChip from "@/components/KChip";
export default {
  components: {
    KChip
  },

  props: {
    is_published: Number,
    url: String,
    category: Object,
    images: Array
  }
};
</script>

<style>
</style>
