<template>
  <div id="editor">
    <markdown-toolbar @click:toggle-view="toggleViewResult" />

    <!--  -->
    <!-- <v-sheet max-height="800" style="overflow-y:auto"> -->
    <v-row class="my-0">
      <!-- Texto -->
      <v-col class="py-0">
        <h2 class="title mb-4 font-weight-light">{{ label || "Texto" }}</h2>
        <!-- <v-divider class="mb-4"></v-divider> -->
        <v-textarea
          ref="textarea"
          placeholder="Use Markdown para formatar o texto. Clique em 'Ajuda com a formatação' para saber mais."
          outlined
          height="500"
          v-model="input"
          :error-messages="errorMessages"
        ></v-textarea>
      </v-col>

      <!-- Visualização -->
      <v-expand-x-transition>
        <v-col class="py-0" v-show="showViewResult">
          <h2 class="title mb-4 font-weight-light">Visualização</h2>
          <v-card
            outlined
            class="pa-2"
            min-height="132"
            max-height="500"
            style="overflow-x: auto"
          >
            <markdown-view v-model="markdown"></markdown-view>
          </v-card>
        </v-col>
      </v-expand-x-transition>
    </v-row>
  </div>
</template>

<script>
import MarkdownToolbar from "./toolbar";
import MarkdownView from "./view";

export default {
  name: "v-markdown",
  components: {
    MarkdownToolbar,
    MarkdownView
  },
  props: {
    // O valor inicial recebido pelo v-model
    value: String,

    // O título acima do campo de texto
    label: String,

    // Mensagens de erro
    errorMessages: String,

    // O número padrão para o timeout de atualização. Números menores
    // resultarão em mais atualizações do markdown e consequente ficará
    // mais pesado.
    timeout: { type: Number, default: 500 }
  },
  data() {
    return {
      input: null,
      markdown: null,
      viewResult: null,

      // Adicionado como teste
      timeoutObject: null
    };
  },
  watch: {
    value() {
      this.input = this.value;
    },

    // Adicinoado como teste
    input() {
      if (this.timeoutObject) clearTimeout(this.timeoutObject);

      this.timeoutObject = setTimeout(() => {
        this.$emit("input", this.input);

        if (!this.input) this.markdown = "";
        else this.markdown = this.input;
      }, this.timeout);
    }
  },

  computed: {
    // Decide se o resultado deve ser exibido
    showViewResult() {
      // Se existe um dado, retorne-o
      if (this.viewResult !== null) {
        return this.viewResult;
      }
      // Se for tela pequena, inicie com ele fechado
      if (this.$vuetify.breakpoint.smAndDown) {
        return false;
      }

      return true;
    }
  },
  methods: {
    /**
     * Alterna a visualização de do texto formatado
     */
    toggleViewResult() {
      this.viewResult = !this.showViewResult;
    },

    /**
     * Insere um texto na textarea
     */
    insert(file) {
      this.replace(file.url);
    },

    replace(url) {
      function getInputSelection(el) {
        var start = 0,
          end = 0,
          normalizedValue,
          range,
          textInputRange,
          len,
          endRange;

        if (
          typeof el.selectionStart == "number" &&
          typeof el.selectionEnd == "number"
        ) {
          start = el.selectionStart;
          end = el.selectionEnd;
        } else {
          range = document.selection.createRange();

          if (range && range.parentElement() == el) {
            len = el.value.length;
            normalizedValue = el.value.replace(/\r\n/g, "\n");

            // Create a working TextRange that lives only in the input
            textInputRange = el.createTextRange();
            textInputRange.moveToBookmark(range.getBookmark());

            // Check if the start and end of the selection are at the very end
            // of the input, since moveStart/moveEnd doesn't return what we want
            // in those cases
            endRange = el.createTextRange();
            endRange.collapse(false);

            if (textInputRange.compareEndPoints("StartToEnd", endRange) > -1) {
              start = end = len;
            } else {
              start = -textInputRange.moveStart("character", -len);
              start += normalizedValue.slice(0, start).split("\n").length - 1;

              if (textInputRange.compareEndPoints("EndToEnd", endRange) > -1) {
                end = len;
              } else {
                end = -textInputRange.moveEnd("character", -len);
                end += normalizedValue.slice(0, end).split("\n").length - 1;
              }
            }
          }
        }

        return {
          start: start,
          end: end
        };
      }

      const replaceSelectedText = (el, text) => {
        var sel = getInputSelection(el);
        if (!this.input) {
          this.input = text;
        } else {
          this.input =
            this.input.slice(0, sel.start) + text + this.input.slice(sel.end);
        }
      };

      // var el = document.getElementById("your_textarea");
      const textarea = this.$refs["textarea"];
      var el = textarea.$el.querySelector("textarea");
      var sel = getInputSelection(el);
      // console.log("selecção", sel);

      replaceSelectedText(el, `![](${url})`);
      // replaceSelectedText(el, "[NEW TEXT]");
    }
  },
  mounted() {
    this.input = this.value;
  }
};
</script>

<style>
</style>