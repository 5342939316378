<template>
  <v-list-item @click.stop.prevent="$emit('click:edit')">
    <!--  -->
    <v-list-item-avatar>
      <v-icon>mdi-help-circle-outline</v-icon>
    </v-list-item-avatar>
    <!-- Conteúdo -->
    <v-list-item-content>
      <!-- Primeira linha -->
      <v-list-item-title>
        <span class="caption grey--text">#{{ item.id }}</span>
        {{ item.title }}
        <span v-if="!item.title" class="font-italic grey--text"
          >(Rascunho sem título)</span
        >
      </v-list-item-title>

      <!-- Segunda linha -->
      <v-list-item-subtitle>
        <AdminChips v-bind="item" />
      </v-list-item-subtitle>
    </v-list-item-content>

    <!-- Ações -->
    <v-list-item-action>
      <v-row>
        <!-- Botão Abrir URL -->
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn icon @click.stop.prevent="$emit('click:open')" v-on="on">
              <v-icon>mdi-link</v-icon>
            </v-btn>
          </template>
          Abrir FAQ
        </v-tooltip>

        <!-- Botão Delete -->
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              icon
              @click.stop.prevent="() => $emit('click:remove')"
              v-on="on"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>
          Remover item
        </v-tooltip>
      </v-row>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
import AdminChips from "./AdminChips";
export default {
  components: {
    AdminChips
  },
  props: {
    item: Object
  }
};
</script>

<style>
</style>
