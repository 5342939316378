<template>
  <KDialog
    :visible="visible"
    :title="title"
    :loading="loading"
    :actions="[{ label: 'Importar', eventName: 'click:import' }]"
    @click:close="close"
    @click:import="saveImport"
  >
    <KForm ref="form">
      <v-row>
        <!--  -->
        <v-col cols="12">
          <SectionHeader title="Importar" />
        </v-col>

        <!--  -->
        <!-- <v-col cols="12">
          <KAlert info title="Informação">
            <div>
              Para importar o conteúdo de um arquivo, é preciso capturar sua
              <b>URL RAW</b> no Github.
            </div>
          </KAlert>
        </v-col> -->

        <!--  -->
        <!-- <v-col cols="12">
          <v-card class="overflow-hidden">
            <img src="/images/faqs/help-importation.png" width="100%" />
          </v-card>
        </v-col> -->

        <!--  -->
        <v-col cols="12">
          <v-text-field
            label="URL do arquivo"
            outlined
            dense
            hint="URL no  formato:  https://raw.github.com/[user]/[repo]/[branch]/[folder]/[file].md"
            persistent-hint
            :rules="[$validation.isRequired]"
            :error-messages="errors.url"
            v-model="form.url"
          />
        </v-col>

        <!--  -->
        <v-col cols="12">
          <v-switch
            inset
            label="Publicar?"
            :error-messages="errors.is_published"
            v-model="form.is_published"
          />
        </v-col>
        <!--  -->
        <v-col cols="12">
          <v-text-field
            label="Título"
            outlined
            dense
            :rules="[$validation.isRequired]"
            :error-messages="errors.title"
            v-model="form.title"
          />
        </v-col>

        <!--  -->
        <v-col cols="12">
          <v-autocomplete
            dense
            outlined
            label="Categoria"
            item-text="name"
            item-value="id"
            :items="categories"
            :rules="[$validation.isRequired]"
            :error-messages="errors.faq_category_id"
            v-model="form.faq_category_id"
          ></v-autocomplete>
        </v-col>
      </v-row>
    </KForm>
    {{ form }}
  </KDialog>
</template>

<script>
import KForm from "@/components/KForm";
import KAlert from "@/components/KAlert";
import KDialog from "@/components/KDialog";
import SectionHeader from "@/components/SectionHeader";

export default {
  components: {
    KForm,
    KAlert,
    KDialog,
    SectionHeader
  },

  data() {
    return {
      form: {},
      errors: {},

      visible: false,
      loading: false,
      title: null
    };
  },

  computed: {
    categories() {
      return this.$store.getters["faq_categories/all"];
    }
  },

  methods: {
    async saveImport() {
      try {
        if (this.$refs.form.validate()) {
          this.loading = true;
          await this.$store.dispatch("faqs/importFromGithub", this.form);
          this.loading = false;
          this.$message.success("FAQ importada com sucesso");
          this.close();
        }
      } catch (error) {
        this.loading = false;
        this.errors = this.$message.serverError(error);
      }
    },

    openToImport(payload) {
      this.form = {};
      this.title = "Importar Pergunta Frequente";

      this.visible = true;
      if (this.$refs.form) this.$refs.form.resetValidation();
    },

    close() {
      this.form = {};
      this.visible = false;
    }
  }
};
</script>

<style>
</style>
