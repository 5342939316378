<template>
  <k-page>
    <!-- Barra de pesquisa -->
    <FilterBar />
    <v-card outlined class="overflow-hidden mb-12">
      <v-row no-gutters>
        <v-col cols="12" xs="12" class="pa-0">
          <v-list class="pa-0 bordered">
            <AdminListItem
              v-for="(faq, key) in faqs"
              :key="'item-' + key"
              :item="faq"
              @click:edit="edit(faq)"
              @click:open="openUrl(faq)"
              @click:remove="remove(faq)"
            />
          </v-list>
        </v-col>
      </v-row>
    </v-card>

    <!-- Caixa de Diálogo -->
    <DialogSave ref="dialog_save" />

    <!-- Caixa de Diálogo de Remoção -->
    <DialogDelete ref="dialog_delete" />
    <DialogImport ref="dialog_import" />
    <DialogSync ref="dialog_sync" />

    <!-- Botão FAB -->
    <SpeedDial
      :visible="!loading"
      :actions="speedDialActions"
      @click:add="openToAdd"
      @click:import="openToImport"
      @click:sync="openToSync"
    ></SpeedDial>
  </k-page>
</template>

<script>
import KPage from "@/components/KPage";
import KForm from "@/components/KForm";
import KChip from "@/components/KChip";
import KAvatar from "@/components/KAvatar";
import SpeedDial from "@/components/SpeedDial";

// Internos
import FilterBar from "./FilterBar";
import AdminListItem from "../_shared/AdminListItem";

// Dialogs
import DialogSave from "./DialogSave";
import DialogSync from "./DialogSync";
import DialogDelete from "./DialogDelete";
import DialogImport from "./DialogImport";

export default {
  components: {
    KPage,
    KForm,
    KChip,
    KAvatar,
    SpeedDial,

    FilterBar,
    AdminListItem,

    DialogSave,
    DialogSync,
    DialogDelete,
    DialogImport
  },

  data() {
    return {
      //
      // faqs: [],

      //
      loading: false,

      //
      dialog: {
        visible: true
      }
    };
  },

  computed: {
    /**
     * Alimenta os partidos de faqa reativa
     */
    faqs() {
      return this.$store.getters["faqs/all"];
    },
    speedDialActions() {
      return [
        {
          eventName: "click:add",
          label: "Nova Pergunta Frequente",
          icon: "mdi-plus",
          color: "cyan"
        },
        {
          eventName: "click:import",
          label: "Importar do Github",
          icon: "mdi-github",
          color: "black"
        },
        {
          eventName: "click:sync",
          label: "Sincronizar Importados",
          icon: "mdi-cloud-sync-outline",
          color: "blue"
        }
      ];
    }
  },

  //
  methods: {
    /**
     *
     */
    openToAdd() {
      this.$refs["dialog_save"].openToAdd();
    },

    /**
     *
     */
    openToImport() {
      this.$refs["dialog_import"].openToImport();
    },

    /**
     *
     */
    openToSync() {
      this.$refs["dialog_sync"].openToSync();
    },

    /**
     * Abre o dialog_save como cadastro
     */
    edit(data) {
      this.$refs["dialog_save"].openToEdit(data);
    },

    /**
     *
     */
    openUrl({ id }) {
      this.$router.push(`/help/faqs/faq/${id}`);
    },

    /**
     *
     */
    remove(data) {
      this.$refs["dialog_delete"].openToRemove(data);
    },

    /**
     * Carrega os partidos caso ainda não estejam
     */
    async loadData() {
      try {
        this.loading = true;
        this.$loading.show();
        await Promise.all([
          this.$store.dispatch("faqs/all", { show_unpublished: true }),
          this.$store.dispatch("faq_categories/all")
        ]);
        this.$loading.hide();
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.$loading.hide();
        this.$message.serverError(error);
      }
    }
  },

  mounted() {
    // this.$store.dispatch("faqs/refresh");
    this.loadData();
    // teste
    // this.add();
  }
};
</script>

