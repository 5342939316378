<template>
  <v-text-field
    label="Nome do Ícone"
    hint="Encontrado em materialdesignicons.com"
    persistent-hint
    placeholder="Nome do Ícone"
    :append-icon="currentIcon"
    prefix="mdi-"
    v-model="icon"
    required
    v-bind="$attrs"
  >
    <template v-slot:append-outer>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-icon
            color="grey"
            @click="openURL('https://materialdesignicons.com')"
            v-on="on"
            >mdi-open-in-new</v-icon
          >
        </template>
        Ir para a URL http://materialdesignicons.com
      </v-tooltip>
    </template>
  </v-text-field>
</template>

<script>
export default {
  name: "k-input-icon",
  extends: "v-text-field",
  props: {
    value: String
  },

  data() {
    return {
      icon: null
    };
  },

  watch: {
    icon(newValue, oldValue) {
      if (this.icon) {
        this.$emit("input", ("mdi-" + this.icon).trim());
      } else {
        this.$emit("input", "");
      }
    },
    value(newValue, oldValue) {
      this.initial();
    }
  },

  computed: {
    currentIcon() {
      return "mdi-" + this.icon;
    }
  },
  methods: {
    initial() {
      if (!this.value) {
        this.icon = "";
      } else {
        this.icon = this.value && String(this.value).replace("mdi-", "");
      }
    },

    openURL(url, target = "_blank") {
      window.open(url, target);
    }
  },
  mounted() {
    this.initial();
  }
};
</script>

<style>
</style>
