<template>
  <v-toolbar flat class="pl-2">
    <!-- Botão de Atualizar -->
    <v-tooltip bottom>
      <template v-slot:activator="{on}">
        <v-btn icon @click="refresh" v-on="on">
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
      </template>
      Atualizar Dados
    </v-tooltip>
    <!-- Filtro por status -->
  </v-toolbar>
</template>

<script>
export default {
  name: "toolbar",
  components: {},
  props: {
    statuses: Object,
    page: Number
  },
  data() {
    return {
      query: {
        limit: 50
      }
    };
  },

  watch: {
    page(page) {
      this.refresh();
    }
  },
  computed: {},

  methods: {
    /**
     * Atualiza
     */
    async refresh() {
      try {
        await this.$nextTick();
        const payload = { page: this.page, ...this.query };

        this.loading = true;
        this.$loading.show();
        await this.$store.dispatch("faq_categories/refresh", payload);
        this.$loading.hide();
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.$message.serverError(error);
        this.$loading.hide();
      }
    }
  }
};
</script>

<style>
</style>