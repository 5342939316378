<template>
  <div>
    <v-toolbar flat color="transparent">
      <v-spacer></v-spacer>

      <!--  -->
      <v-tooltip bottom>
        <template v-slot:activator="{on}">
          <v-btn :icon="$vuetify.breakpoint.smAndDown" text @click="showHelp" v-on="on">
            <v-icon>mdi-help</v-icon>
            <span v-if="$vuetify.breakpoint.mdAndUp">Ajuda com a formatação</span>
          </v-btn>
        </template>
        Ajuda com formatação
      </v-tooltip>

      <!--  -->
      <v-tooltip bottom>
        <template v-slot:activator="{on}">
          <v-btn :icon="$vuetify.breakpoint.smAndDown" text @click="toggleViewResult" v-on="on">
            <v-icon>mdi-eye{{showViewResult?'-off':''}}</v-icon>
            <span
              v-if="$vuetify.breakpoint.mdAndUp"
            >{{!showViewResult ? 'Mostrar':'Ocultar'}} Visualização</span>
          </v-btn>
        </template>
        {{!showViewResult ? 'Mostrar':'Ocultar'}} Visualização
      </v-tooltip>
    </v-toolbar>

    <!-- Dialog de ajuda de formação -->
    <!-- <v-row justify="center" color="primary"> -->
    <v-dialog
      scrollable
      v-model="dialog"
      :fullscreen="$vuetify.breakpoint.smAndDown"
      max-width="800px"
    >
      <v-card>
        <!-- Cabeçalho -->
        <v-card-title>
          <v-btn icon>
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <span class="headline">Ajuda para Formatação</span>
        </v-card-title>
        <!-- Divisão -->
        <v-divider />
        <!-- Conteúdo -->
        <v-card-text>
          <k-alert class="mt-1">
            O CandidAPP usa a ferramenta Markdown para formatação. Aqui estão as noções básicas.
            <a
              href="https://www.markdownguide.org/basic-syntax/"
              target="_blank"
            >Consulte a documentação</a> para mais detalhes.
          </k-alert>

          <h2 class="title font-weight-light">Cabeçalhos</h2>
          <v-row>
            <v-col class="pa-1 pl-4"># Cabeçalho 1</v-col>
            <v-col class="pa-1">
              <h1>Cabeçalho 1</h1>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pa-1 pl-4">## Cabeçalho 2</v-col>
            <v-col class="pa-1">
              <h2>Cabeçalho 2</h2>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pa-1 pl-4">### Cabeçalho 3</v-col>
            <v-col class="pa-1">
              <h3>Cabeçalho 3</h3>
            </v-col>
          </v-row>
          <!--  -->
          <h2 class="title font-weight-light">Parágrafos</h2>
          <v-row>
            <v-col>Adicione duas novas linhas para criar parágrafos</v-col>
          </v-row>

          <!--  -->
          <h2 class="title font-weight-light">Negrito</h2>
          <v-row>
            <v-col>CandidAPP é **muito** bom</v-col>
            <v-col>
              CandidAPP é
              <strong>muito</strong> bom
            </v-col>
          </v-row>

          <!--  -->
          <h2 class="title font-weight-light">Ênfase</h2>
          <v-row>
            <v-col>CandidAPP é *muito* bom</v-col>
            <v-col>
              CandidAPP é
              <em>muito</em> bom
            </v-col>
          </v-row>

          <!--  -->
          <h2 class="title font-weight-light">Listas não ordenadas</h2>
          <v-row>
            <v-col>
              <p class="ma-1">- Pesquisar textos</p>
              <p class="ma-1">- Publicar notícia</p>
            </v-col>
            <v-col>
              <ul>
                <li class="ma-1">Pesquisar textos</li>
                <li class="ma-1">Publicar notícia</li>
              </ul>
            </v-col>
          </v-row>
          <h2 class="title font-weight-light">Listas ordenadas</h2>
          <v-row>
            <v-col>
              <p class="ma-1">1. Pesquisar textos</p>
              <p class="ma-1">2. Publicar notícia</p>
            </v-col>
            <v-col>
              <ol>
                <li class="ma-1">Pesquisar textos</li>
                <li class="ma-1">Publicar notícia</li>
              </ol>
            </v-col>
          </v-row>

          <!--  -->
          <h2 class="title font-weight-light">Links</h2>
          <v-row>
            <v-col>[Link Externo](https://candidapp.com.br)</v-col>
            <v-col>
              <a href="https://candidapp.com.br">Link Externo</a>
            </v-col>
          </v-row>

          <v-row>
            <v-col>[Link Interno](/profile)</v-col>
            <v-col>
              <a href="/profile" target="_blank">Link Interno</a>
            </v-col>
          </v-row>
          <v-row>
            <v-col>Links internos devem começar com uma "/" e links externos devem começar com http ou https.</v-col>
          </v-row>

          <!--  -->
          <h2 class="title font-weight-light">Imagens</h2>
          <v-row>
            <v-col>![Texto da Imagem](https://example.com/nome-imagem.png)</v-col>
          </v-row>

          <!--  -->
        </v-card-text>

        <!-- Botões de ação -->
        <v-divider />
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="dialog=false">Fechar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "markdown-toolbar",
  data() {
    return {
      dialog: false,
      showViewResult: false,
    };
  },
  methods: {
    toggleViewResult() {
      this.$emit("click:toggle-view");
    },
    showHelp() {
      console.log("clic sholhelp");
      this.dialog = !this.dialog;
    },
  },
};
</script>

<style scoped>
</style>