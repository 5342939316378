<template>
  <KDialog
    fullscreen
    :title="title"
    :loading="loading"
    :visible="visible"
    :actions="dialogActions"
    @click:add="save"
    @click:edit="save"
    @click:close="close"
  >
    <KForm ref="form">
      <v-row class="my-0">
        <!--  -->
        <v-col cols="12" lg="2" class="py-1">
          <v-switch
            label="Publicada"
            color="primary"
            inset
            :false-value="0"
            :true-value="1"
            :error-messages="errors.is_published"
            v-model="data.is_published"
            required
          ></v-switch>
        </v-col>

        <!--  -->
        <v-col cols="12" lg="4" class="py-1">
          <v-text-field
            outlined
            :rules="[$validation.isRequired]"
            label="Título"
            dense
            :error-messages="errors.title"
            v-model="data.title"
            required
          ></v-text-field>
        </v-col>

        <!--  -->
        <v-col cols="12" md="6" lg="3" class="py-1">
          <v-autocomplete
            outlined
            :rules="[$validation.isRequired]"
            label="Categoria"
            dense
            :items="categories"
            item-text="name"
            item-value="id"
            :error-messages="errors.faq_category_id"
            v-model="data.faq_category_id"
            required
          ></v-autocomplete>
        </v-col>

        <v-divider></v-divider>

        <!--  -->
        <v-col cols="12">
          <Markdown
            ref="markdown"
            label="Texto da FAQ"
            v-model="data.text"
            :error-messages="errors.text"
          />
        </v-col>
      </v-row>
    </KForm>
  </KDialog>
</template>

<script>
import KForm from "@/components/KForm";
import KDialog from "@/components/KDialog";
import Markdown from "@/components/Markdown";
import Upload from "@/components/Upload";

export default {
  components: {
    KForm,
    KDialog,
    Markdown,
    Upload
  },

  data() {
    return {
      data: {},
      errors: {},
      editing: false,

      visible: false,
      title: null, //título da dialog
      loading: false,

      mergeData: { id: null }
    };
  },

  computed: {
    dialogActions() {
      if (this.editing) {
        return [
          {
            eventName: "click:edit",
            label: "Salvar Alterações"
          }
        ];
      } else {
        return [
          {
            eventName: "click:add",
            label: "Cadastrar"
          }
        ];
      }
    },

    categories() {
      return this.$store.getters["faq_categories/all"];
    }
  },

  methods: {
    /**
     * Salva os dados no Store
     */
    async save() {
      try {
        //
        this.errors = {};

        // Se as demais validações passaram
        if (this.$refs["form"].validate()) {
          this.loading = true;

          //
          const data = await this.$store.dispatch("faqs/save", {
            payload: this.data,
            editing: this.editing
          });

          this.mergeData.id = data.id;
          // console.log("mergeData", this.mergeData);
          // console.log("data", data);

          this.loading = false;
          this.$message.success("FAQ salva com sucesso");
          this.close();
        } else {
          this.$message.error("Verifique os campos marcados em vermelho");

          this.loading = false;
        }
      } catch (error) {
        // console.log(error);

        this.errors = this.$message.serverError(error);
        this.loading = false;
      }
    },

    /*******************************************************
     *
     *  FUNÇÕES BÁSICAS DE QUALQUER DIALOG
     *
     *******************************************************/
    /**
     *
     */
    async uploadAll() {
      await this.$refs["upload"].uploadAll();
    },

    /**
     *
     */
    insertIntoTextarea(file) {
      this.$refs["markdown"].insert(file);
    },

    /**
     * Abre o dialog preparado para cadastrar um novo dado
     */
    async openToAdd() {
      try {
        // Altera o título
        this.title = "Cadastrar Pergunta Frequente";

        // Limpa os dados
        this.clear();

        // Define se é uma edição
        this.editing = true;

        // Cria um rascunho
        this.$loading.show("Aguarde. Estamos criando um rascunho");
        this.data = await this.$store.dispatch("faqs/draft");

        // Marque como não sujo

        this.mergeData.id = this.data.id;
        this.$loading.hide();

        // Abre por último, depois que está tudo preenchido
        this.open();
      } catch (error) {
        // console.log(error);

        this.$message.serverError(error);
        this.$loading.hide();
      }
    },

    /**
     * Abre o dialog preparado para alteerar os dados
     */
    openToEdit(payload) {
      // Altera o título

      // Mescla os dados recebidos para não alterar o que está para trás
      this.data = { ...payload };
      this.title = `Editar "${payload.title || "rascunho"}"`;

      this.mergeData.id = payload.id;

      // Marque como não sujo

      // Define se é uma edição
      this.editing = true;

      // Abre por último, depois que está tudo preenchido
      this.open();
    },

    /**
     * Abre o dialog
     */
    open() {
      this.visible = true;
      if (this.$refs.form) this.$refs.form.resetValidation();
    },

    /**
     * Fecha o dialog
     */
    close() {
      // const closeForm = () => {
      // this.$refs.form.resetValidation();
      // setTimeout(() =>
      // , 500);
      // };
      this.visible = false;
      this.clear();
    },

    /**
     * Limpa os dados do dialog\
     */
    clear() {
      this.data = {};
    }
  }
};
</script>

<style>
</style>
