<template>
  <!--
    Exibe um botão FAB com opções


    Forma de Uso
     
          <SpeedDial
            color="accent"
            :actions="[ { 
              label: 'Nova pessoa', 
              icon: 'mdi-plus', 
              eventName: 'click:add',
              color: 'accent'
            },]"

            @click:add="onClickAdd"

  -->

  <v-fab-transition>
    <v-speed-dial
      v-show="visible"
      v-model="fab"
      direction="top"
      bottom
      right
      fixed
      :open-on-hover="false"
      :transition="'slide-y-reverse-transition'"
    >
      <template v-slot:activator>
        <v-btn v-model="fab" :color="color" dark fab :class="{ rotate: fab }">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>

      <v-tooltip
        left
        v-for="(action, i) in actions"
        :color="action.color || color"
        :value="state.labels[i]"
        :key="action.id"
        transition="slide-x-reverse-transition"
      >
        <template v-slot:activator="{}">
          <v-btn
            fab
            dark
            small
            :color="action.color || color"
            @click="onItemClick(action)"
          >
            <v-icon>{{ action.icon }}</v-icon>
          </v-btn>
        </template>
        <span>{{ action.label }}</span>
      </v-tooltip>
    </v-speed-dial>
  </v-fab-transition>
</template>

<script>
import Vue from "vue";
export default {
  props: {
    actions: { type: Array, required: true },
    color: { type: String, default: "accent" },
    //
    interval: { type: [String, Number], default: 50 },
    timeout: { type: [String, Number], default: 300 }
  },
  data: () => ({
    fab: false,
    showtip: {},
    visible: false,
    state: {
      labels: []
    }
  }),

  watch: {
    fab(value) {
      if (value) {
        this.actions.forEach((item, i) => {
          setTimeout(async () => {
            Vue.set(this.state.labels, i, true);
          }, this.timeout + this.interval * i);
        });
      } else {
        this.actions.forEach((item, i) => {
          this.state.labels = [];
        });
      }
    }
  },

  methods: {
    /**
     * Emite o evento click passando o id como valor e também o evento click:[id]
     *
     * Ex.: Se o id for "add", os seguintes eventos serão emitidos
     * $emit('click', 'add')
     * $emit('click:add')
     */
    onItemClick(action) {
      this.$emit(action.eventName);
      // this.$emit("click:" + eventName);
      // this.$emit("click", eventName);
    }
  },

  mounted() {
    setTimeout(() => (this.visible = true), 500);
  },

  beforeDestroy() {
    this.visible = false;
  }
};
</script>

<style scoped>
.rotate {
  transition-duration: 0.25s;
  transform: rotate(45deg);
}
</style>
