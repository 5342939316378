<template>
  <KDialog
    :title="title"
    :loading="loading"
    :visible="visible"
    :actions="dialogActions"
    @click:add="save"
    @click:edit="save"
    @click:close="close"
  >
    <KForm ref="form">
      <v-container>
        <v-row class="my-0">
          <!-- Nome -->
          <v-col cols="12">
            <v-text-field
              outlined
              dense
              :rules="[$validation.isRequired]"
              label="Nome"
              :error-messages="this.errors.name"
              v-model="data.name"
              required
            ></v-text-field>
          </v-col>

          <!-- Descrição -->
          <v-col cols="12">
            <v-text-field
              outlined
              dense
              :rules="[$validation.isRequired]"
              label="Descrição"
              :error-messages="this.errors.description"
              v-model="data.description"
              required
            ></v-text-field>
          </v-col>

          <!-- Ícone -->
          <v-col cols="12" xs="12">
            <KInputIcon
              outlined
              dense
              v-model="data.icon"
              :rules="[$validation.isRequired]"
              :error-messages="errors.icon"
            ></KInputIcon>
          </v-col>
        </v-row>
      </v-container>
    </KForm>
  </KDialog>
</template>

<script>
import KForm from "@/components/KForm";
import KAlert from "@/components/KAlert";
import KDialog from "@/components/KDialog";
import KInputIcon from "@/components/KInput/Icon";

export default {
  components: {
    KForm,
    KAlert,
    KDialog,
    KInputIcon
  },

  data() {
    return {
      // Dados
      data: {},
      errors: {},
      editing: false,
      // Dialog
      visible: false,
      loading: false,
      title: null
    };
  },

  computed: {
    dialogActions() {
      if (this.editing) {
        return [
          {
            label: "Salvar Alterações",
            eventName: "click:edit"
          }
        ];
      } else {
        return [
          {
            label: "Cadastrar",
            eventName: "click:add"
          }
        ];
      }
    }
  },

  methods: {
    /*******************************************************
     *
     *  FUNÇÕES DE GERENCIAMENTO DO UPLOAD
     *
     *******************************************************/

    /*******************************************************
     *
     *  FUNÇÕES PARA SALVAR E EDITAR OS DADOS NO STORE
     *
     *******************************************************/

    /**
     * Salva os dados no Store
     */
    async save() {
      try {
        //
        this.errors = {};

        // Se as demais validações passaram
        if (this.$refs["form"].validate()) {
          this.loading = true;
          //
          await this.$store.dispatch("faq_categories/save", {
            payload: this.data,
            editing: this.editing
          });

          this.loading = false;
          this.$message.success("Categoria salva com sucesso");
          this.close();
        } else {
          this.$message.error("Verifique os campos marcados em vermelho");

          this.loading = false;
        }
      } catch (error) {
        this.errors = this.$message.serverError(error);
        this.loading = false;
      }
    },

    /*******************************************************
     *
     *  FUNÇÕES BÁSICAS DE QUALQUER DIALOG
     *
     *******************************************************/

    /**
     * Abre o dialog preparado para cadastrar um novo dado
     */
    add() {
      // Altera o título
      this.title = "Cadastrar Categoria";

      // Limpa os dados
      this.clear();

      this.data = {};

      // Define se é uma edição
      this.editing = false;

      // Abre por último, depois que está tudo preenchido
      this.open();
    },

    /**
     * Abre o dialog preparado para alteerar os dados
     */
    edit({ id, name, icon, description }) {
      // Altera o título
      this.title = `Editar "${name}"`;

      // Mescla os dados recebidos para não alterar o que está para trás
      this.data = {
        id,
        name,
        icon,
        description
      };

      // Define se é uma edição
      this.editing = true;

      // Abre por último, depois que está tudo preenchido
      this.open();
    },

    /**
     * Abre o dialog
     */
    open() {
      this.visible = true;
    },

    /**
     * Fecha o dialog
     */
    close() {
      this.$refs.form.resetValidation();
      this.visible = false;
    },

    /**
     * Limpa os dados do dialog
     */
    clear() {
      this.data = {};
    }
  }
};
</script>

<style>
</style>
