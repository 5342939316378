<template>
  <v-toolbar flat class="pl-2">
    <!-- Botão de Atualizar -->
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-btn icon @click="refresh" v-on="on">
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
      </template>
      Atualizar Dados
    </v-tooltip>

    <v-select
      @change="refresh"
      v-model="query.category"
      flat
      clearable
      :items="categories"
      item-text="name"
      item-value="id"
      placeholder="Categoria"
      class="mr-1"
      solo
      hide-details
    ></v-select>

    <v-text-field
      @keyup.enter="refresh"
      @click:clear="refresh"
      v-model="query.q"
      flat
      clearable
      solo
      hide-details
      placeholder="Pesquisar"
    ></v-text-field>
  </v-toolbar>
</template>

<script>
export default {
  name: "toolbar",
  components: {},
  props: {
    statuses: Object,
    page: Number
  },
  data() {
    return {
      query: {
        limit: 50,
        q: null,
        category: null,
        show_unpublished: true
      }
    };
  },

  watch: {
    page(page) {
      this.refresh();
    }
  },
  computed: {
    categories() {
      return this.$store.getters["faq_categories/all"];
    }
  },

  methods: {
    /**
     * Atualiza
     */
    async refresh() {
      try {
        await this.$nextTick();
        const payload = { page: this.page, ...this.query, limit: 50 };
        this.$loading.show();
        await this.$store.dispatch("faqs/refresh", payload);
        this.$loading.hide();
      } catch (error) {
        this.$message.serverError(error);
        this.$loading.hide();
      }
    }
  }
};
</script>

<style>
</style>