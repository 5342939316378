<template>
  <KDialog
    :visible="visible"
    :title="title"
    :loading="loading"
    :actions="[{ label: 'Sincronizar', eventName: 'click:sync' }]"
    @click:close="close"
    @click:sync="saveSync"
  >
    <KForm ref="form">
      <v-row>
        <!--  -->
        <v-col cols="12">
          <SectionHeader title="Sincronizar" />
        </v-col>

        <v-col cols="12">
          <KAlert title="Atenção" warning>
            Todas FAQs registradas serão atualizadas.
          </KAlert>
        </v-col>

        <v-col cols="12">
          <v-card outlined>
            <v-list class="py-0 bordered">
              <v-list-item v-for="item in synchronizedItems" :key="item.id">
                <v-list-item-avatar>
                  <v-icon color="red" v-if="item.error">mdi-alert</v-icon>
                  <v-icon color="green" v-else>mdi-check</v-icon>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title>
                    <KChip>#{{ item.id }}</KChip>
                    {{ item.title }}
                  </v-list-item-title>

                  <v-list-item-subtitle>
                    {{ item.message }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </v-col>
      </v-row>
    </KForm>
  </KDialog>
</template>

<script>
import KForm from "@/components/KForm";
import KChip from "@/components/KChip";
import KAlert from "@/components/KAlert";
import KDialog from "@/components/KDialog";
import SectionHeader from "@/components/SectionHeader";

export default {
  components: {
    KForm,
    KChip,
    KAlert,
    KDialog,
    SectionHeader
  },

  data() {
    return {
      // form: {},
      errors: {},
      synchronizedItems: [],

      visible: false,
      loading: false,
      title: null
    };
  },

  methods: {
    async saveSync() {
      try {
        if (this.$refs.form.validate()) {
          this.loading = true;
          const result = await this.$store.dispatch("faqs/synchronizeImported");

          this.synchronizedItems = result;

          console.log(result);
          this.loading = false;
          this.$message.success("FAQ sincronizada com sucesso");
          // this.close();
        }
      } catch (error) {
        this.loading = false;
        this.errors = this.$message.serverError(error);
      }
    },

    openToSync(payload) {
      this.synchronizedItems = [];
      this.title = "Sincronizar Perguntas Frequentes";

      this.visible = true;
    },

    close() {
      this.synchronizedItems = [];
      this.visible = false;
    }
  }
};
</script>

<style>
</style>
